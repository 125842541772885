<template>
  <span>
    <v-card>
      <v-img
        v-if="item.type === 'image'"
        height="250"
        :lazy-src="`${fileBaseUrl}/${item.companyUuid}/${item.uuid}-100.${item.extension}`"
        :src="`${fileBaseUrl}/${item.companyUuid}/${item.uuid}.${item.extension}`"
        @click="
          permissions.write
            ? (editMediaDialog = true)
            : (editMediaDialog = false)
        "
        :style="permissions.write ? 'cursor:pointer' : ''"
      ></v-img>

      <v-btn
        tile
        v-if="selectable"
        color="green"
        dark
        width="100%"
        @click="useItem()"
      >
        <v-icon left> mdi-check </v-icon>
        Auswählen
      </v-btn>

      <v-card-title>{{ item.name }}</v-card-title>

      <v-card-text>
        {{ item.description }}
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-text class="caption">
        Typ: {{ item.type }}<br />
        Ext: {{ item.extension }}<br />
        Hochgeladen: {{ item.created }} von {{ item.creatorName }}
      </v-card-text>

      <v-card-actions v-if="!selectmode">
        <v-btn
          fab
          depressed
          x-small
          color="red"
          class="white--text"
          :disabled="disabledByPermission(permissions.delete)"
          @click="deleteMedia(item.uuid)"
        >
          <v-icon> mdi-trash-can </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          fab
          depressed
          x-small
          color="green"
          class="white--text"
          :disabled="disabledByPermission(permissions.write)"
          @click="editMediaDialog = true"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <AreYouSureDialog
      :title="this.$t('plugins.frontend.createFrontendButton')"
      :text="this.$t('plugins.frontend.createFrontendOptIn')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />

    <v-dialog
      v-model="editMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="editMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title> Ein Medium bearbeiten </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <EditMedia
            :item="item"
            :key="componentKey"
            @uploaded="mediaUploaded"
            @progress="progress"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import EditMedia from "@/components/plugins/mediapool/EditMedia";
export default {
  name: "MediaCard",

  components: {
    AreYouSureDialog,
    DataPatcher,
    EditMedia,
  },

  data() {
    return {
      componentKey: 0,
      editMediaDialog: false,
      uploading: false,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      patchRouteMedia: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/mediapool/media/`,
      componentKey: 0,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      fetchinData: false, // should be true when fetching data
    };
  },

  props: ["item", "permissions", "selectable", "selectmode"],

  created: function () {},

  methods: {
    useItem() {
      // single item use (in selectmode)
      // emit back to slice component
      // not multiple
      this.$emit("itemSelected", {
        uuid: this.item.uuid,
        extension: this.item.extension,
        type: this.item.type,
      });
    },

    progress(value) {
      this.uploading = value;
    },
    mediaUploaded(value) {
      console.log(value);
    },
    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.createFrontend();
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    reversedMedia: function () {
      return this.data.media.reverse();
    },
  },

  mixins: [api, helpers],
};
</script>
