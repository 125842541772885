<template>
  <v-container>
    <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
      <v-row>
        <v-col cols="12">
          <v-img
            v-if="item.type === 'image'"
            max-height="400"
            contain
            :lazy-src="`${fileBaseUrl}/${item.companyUuid}/${item.uuid}-800.${item.extension}`"
            :src="`${fileBaseUrl}/${item.companyUuid}/${item.uuid}.${item.extension}`"
          ></v-img>
        </v-col>

        <v-card width="100%">
          <v-card-title class="text-overline">Meta Daten</v-card-title>
          <v-card-text class="caption">
            Typ: {{ item.type }}<br />
            Extension: {{ item.extension }}<br />
            Hochgeladen: {{ item.created }} von {{ item.creatorName }}<br />
            Letzte Aktualisierung: {{ item.updated }}
          </v-card-text>
        </v-card>
        <v-col cols="12">
          <v-text-field
            :label="$t('phrases.FileName') + '*'"
            v-model="name"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            :label="$t('phrases.Description')"
            v-model="description"
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('phrases.Copyrights')"
            v-model="copyrights"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('phrases.Source')"
            v-model="source"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        depressed
        color="primary"
        :disabled="!isFormValid ? true : false"
        :loading="progress"
        v-on:click="safeData()"
      >
        {{ $t("controles.Safe") }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  data() {
    return {
      name: null,
      description: null,
      copyrights: null,
      source: null,
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      isFormValid: false,
      file: "",
      name: "",
      copyrights: "",
      source: "",
      description: "",
      uploadSafeguarding: false,
      progress: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  props: ["item"],

  created() {
    this.name = this.item.name;
    this.description = this.item.description;
    this.copyrights = this.item.copyrights;
    this.source = this.item.source;
  },

  methods: {
    async safeData() {
      this.progress = true;
      this.$emit("progress", true);

      const params = new URLSearchParams();
      params.append("name", this.name);
      params.append("copyrights", this.copyrights);
      params.append("source", this.source);
      params.append("description", this.description);

      const url = `company/${this.companyID}/mediapool/media/${this.item.uuid}`;

      let response = await this.put(url, params);
      this.progress = false;
      this.$emit("progress", false);

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate original data
        this.item.name = response.data.data[0].name;
        this.item.copyrights = response.data.data[0].copyrights;
        this.item.source = response.data.data[0].source;
        this.item.description = response.data.data[0].description;
        this.item.updated = response.data.data[0].updated;
      }
    },
  },
  mixins: [api, helpers],
};
</script>
