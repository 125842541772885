<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      v-if="!selectmode"
    />

    <v-row v-if="!fetchinData">
      <v-col v-if="readPerm">
        <v-card tile class="my-2">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Categories") }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              fab
              dark
              small
              color="green"
              class="white--text"
              :loading="loading"
              :disabled="disabledByPermission(writePerm)"
              @click="addCategory()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text v-if="data.categories.length > 0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('phrases.Search')"
              single-line
              hide-details
            ></v-text-field>

            <v-data-table
              :loading="fetchingData"
              :headers="headers"
              :items="data.categories"
              :search="search"
              calculate-widths
            >
              <template v-slot:[`item.name`]="props">
                <v-row no-gutters>
                  <v-col md="auto" class="pt-2 mr-1">
                    <a
                      @click="openCategory(props.item.uuid)"
                      class="black--text"
                    >
                      {{ props.item.name }}
                    </a>
                  </v-col>

                  <v-col md="auto">
                    <v-edit-dialog
                      :return-value.sync="props.item.name"
                      @save="save('name', props.item.name, props.item.uuid)"
                    >
                      <v-btn class="mx-1 editbutton" fab x-small elevation="0">
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>

                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.name"
                          label="Edit"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.icon`]="{ item }">
                <v-btn
                  class="ma-1"
                  fab
                  dark
                  depressed
                  x-small
                  color="secondary"
                  @click="openCategory(item.uuid)"
                >
                  <v-icon> mdi-folder-open </v-icon>
                </v-btn>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="
                        deletePrompt(
                          item.uuid,
                          data.categories
                            .map(function (x) {
                              return x.uuid;
                            })
                            .indexOf(item.uuid)
                        )
                      "
                    >
                      <v-list-item-title>
                        {{ $t("controles.Delete") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-text v-else>
            {{ $t("phrases.NoCategories") }}
          </v-card-text>
        </v-card>

        <v-card tile class="my-2">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Media") }}</v-toolbar-title>

            <v-spacer />
            <v-btn
              fab
              dark
              small
              color="green"
              class="white--text"
              :disabled="disabledByPermission(writePerm)"
              @click="addMediaDialog = true"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row v-if="data.media">
              <v-col
                cols="12"
                md="3"
                v-for="item in reversedMedia"
                :key="item.id"
              >
                <MediaCard
                  :item="item"
                  :permissions="auth.permissions"
                  :selectable="selectable"
                  :selectmode="selectmode"
                  @itemSelected="$emit('itemSelected', $event)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-else v-html="$t('phrases.noPermissionsMessage')"></v-col>
    </v-row>

    <v-row v-else>
      <v-col v-html="$t('phrases.noPermissionsMessage')"></v-col>
    </v-row>

    <AreYouSureDialog
      :title="this.$t('plugins.frontend.createFrontendButton')"
      :text="this.$t('plugins.frontend.createFrontendOptIn')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title> Ein Medium hinzufügen </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <AddMedia
            :parentCategoryUuid="parentCategoryUuid"
            :permissions="auth.permissions"
            :mediapoolUuid="data.uuid"
            :key="componentKey"
            @uploaded="mediaUploaded"
            @progress="uploading = true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import AddMedia from "@/components/plugins/mediapool/AddMedia";
import MediaCard from "@/components/plugins/mediapool/MediaCard";
export default {
  name: "Mediapool",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
    AddMedia,
    MediaCard,
  },

  data() {
    return {
      fileBaseUrl: this.$apiBaseURL + "/public/uploads",
      addMediaDialog: false,
      loading: false,
      uploading: false,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/mediapool/category/`,
      patchRouteMedia: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/mediapool/media/`,
      componentKey: 0,
      parentCategoryUuid: null,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: this.$t("plugins.mediapool.name"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      search: null,
      fetchingData: false,
      headers: [
        { text: "", value: "icon", sortable: false, width: 40 },
        { text: "Name", value: "name" },
        { text: "", value: "actions", sortable: false, width: 40 },
      ],
    };
  },

  props: ["selectable", "selectmode"], // false,one,multiple ... Können Bilder zur Verwendung ausgewählt werden

  created: function () {
    this.getData();

    /* VUEX store watcher. It fires when companyUuid changes */
    this.$store.watch(
      () => store.getters["auth/session"].currentCompany.companyUuid,
      (value) => {
        console.log("getdata");
        this.companyID =
          store.getters["auth/session"].currentCompany.companyUuid;
        this.getData();
      }
    );
  },

  watch: {
    parentCategoryUuid: function () {
      // if change, get data
      this.getData();
    },

    $route: {
      handler: function () {
        if (!this.dialogMode) {
          /* nicht im dialog mode */
          this.parentCategoryUuid = this.$route.params.id;
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    mediaUploaded(value) {
      this.addMediaDialog = false;
      this.componentKey += 1;
      // mutate
      this.data.media.unshift(value.data.data[0]);
    },

    openCategory(uuid) {
      // only update the parentCategoryUuid
      // parentCategoryUuid has a watcher

      if (!this.dialogMode) {
        /* nicht im dialog-mode ... user router */
        this.$router.push({
          name: "plugins.mediapool.:id",
          params: { id: uuid },
        });
      } else {
        /* im dialog-mode */
        this.parentCategoryUuid = uuid;
      }
    },

    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.createFrontend();
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    mutateData(value) {
      console.log(value);
    },

    async getData() {
      this.fetchinData = true;

      let response = null;
      if (!this.parentCategoryUuid) {
        response = await this.get(`company/${this.companyID}/mediapool/root`); // await data from mixin
      } else {
        response = await this.get(
          `company/${this.companyID}/mediapool/${this.parentCategoryUuid}`
        );
      }

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
        this.fetchinData = false;
      }
    },

    async addCategory() {
      // /company/{companyuuid}/mediapool/{mediapooluuid}/category
      this.loading = true;

      let response = await this.post(
        `company/${this.companyID}/mediapool/${this.data.uuid}/category`
      );

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.selectedComponent = null;
        this.componentKey += 1;

        // todo Mutate
        this.data.categories = response.data.data;
      }

      this.loading = false;
    },

    async save(itemName, itemValue, itemUuid) {
      const params = new URLSearchParams();
      params.append(itemName, itemValue);
      this.preloader = true;
      const response = await this.patch(
        `${this.patchRoute}${itemUuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  computed: {
    reversedMedia: function () {
      return this.data.media;
    },
  },

  mixins: [api, helpers],
};
</script>
